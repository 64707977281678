// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-article-active-life-find-a-retailer-tsx": () => import("./../../../src/pages/article/active-life/find-a-retailer.tsx" /* webpackChunkName: "component---src-pages-article-active-life-find-a-retailer-tsx" */),
  "component---src-pages-article-active-life-index-tsx": () => import("./../../../src/pages/article/active-life/index.tsx" /* webpackChunkName: "component---src-pages-article-active-life-index-tsx" */),
  "component---src-pages-article-cbd-cansativa-organics-about-cbd-tsx": () => import("./../../../src/pages/article/cbd/cansativa-organics/about-cbd.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cansativa-organics-about-cbd-tsx" */),
  "component---src-pages-article-cbd-cansativa-organics-about-us-tsx": () => import("./../../../src/pages/article/cbd/cansativa-organics/about-us.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cansativa-organics-about-us-tsx" */),
  "component---src-pages-article-cbd-cansativa-organics-contact-us-tsx": () => import("./../../../src/pages/article/cbd/cansativa-organics/contact-us.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cansativa-organics-contact-us-tsx" */),
  "component---src-pages-article-cbd-cansativa-organics-index-tsx": () => import("./../../../src/pages/article/cbd/cansativa-organics/index.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cansativa-organics-index-tsx" */),
  "component---src-pages-article-cbd-cansativa-organics-products-tsx": () => import("./../../../src/pages/article/cbd/cansativa-organics/products.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cansativa-organics-products-tsx" */),
  "component---src-pages-article-cbd-cbd-now-about-us-tsx": () => import("./../../../src/pages/article/cbd/cbd-now/about-us.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-now-about-us-tsx" */),
  "component---src-pages-article-cbd-cbd-now-contact-tsx": () => import("./../../../src/pages/article/cbd/cbd-now/contact.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-now-contact-tsx" */),
  "component---src-pages-article-cbd-cbd-now-index-tsx": () => import("./../../../src/pages/article/cbd/cbd-now/index.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-now-index-tsx" */),
  "component---src-pages-article-cbd-cbd-now-our-mission-tsx": () => import("./../../../src/pages/article/cbd/cbd-now/our-mission.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-now-our-mission-tsx" */),
  "component---src-pages-article-cbd-cbd-now-products-tsx": () => import("./../../../src/pages/article/cbd/cbd-now/products.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-now-products-tsx" */),
  "component---src-pages-article-cbd-cbd-truth-about-us-tsx": () => import("./../../../src/pages/article/cbd/cbd-truth/about-us.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-truth-about-us-tsx" */),
  "component---src-pages-article-cbd-cbd-truth-contact-tsx": () => import("./../../../src/pages/article/cbd/cbd-truth/contact.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-truth-contact-tsx" */),
  "component---src-pages-article-cbd-cbd-truth-donate-tsx": () => import("./../../../src/pages/article/cbd/cbd-truth/donate.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-truth-donate-tsx" */),
  "component---src-pages-article-cbd-cbd-truth-index-tsx": () => import("./../../../src/pages/article/cbd/cbd-truth/index.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-truth-index-tsx" */),
  "component---src-pages-article-cbd-cbd-truth-mission-tsx": () => import("./../../../src/pages/article/cbd/cbd-truth/mission.tsx" /* webpackChunkName: "component---src-pages-article-cbd-cbd-truth-mission-tsx" */),
  "component---src-pages-article-lemonade-diet-tsx": () => import("./../../../src/pages/article/lemonade-diet.tsx" /* webpackChunkName: "component---src-pages-article-lemonade-diet-tsx" */),
  "component---src-pages-article-lung-health-contact-us-tsx": () => import("./../../../src/pages/article/lung-health/contact-us.tsx" /* webpackChunkName: "component---src-pages-article-lung-health-contact-us-tsx" */),
  "component---src-pages-article-lung-health-index-tsx": () => import("./../../../src/pages/article/lung-health/index.tsx" /* webpackChunkName: "component---src-pages-article-lung-health-index-tsx" */),
  "component---src-pages-article-safe-environment-about-us-tsx": () => import("./../../../src/pages/article/safe-environment/about-us.tsx" /* webpackChunkName: "component---src-pages-article-safe-environment-about-us-tsx" */),
  "component---src-pages-article-safe-environment-contact-us-tsx": () => import("./../../../src/pages/article/safe-environment/contact-us.tsx" /* webpackChunkName: "component---src-pages-article-safe-environment-contact-us-tsx" */),
  "component---src-pages-article-safe-environment-index-tsx": () => import("./../../../src/pages/article/safe-environment/index.tsx" /* webpackChunkName: "component---src-pages-article-safe-environment-index-tsx" */),
  "component---src-pages-class-tsx": () => import("./../../../src/pages/class.tsx" /* webpackChunkName: "component---src-pages-class-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-green-coffee-magic-tsx": () => import("./../../../src/pages/green-coffee-magic.tsx" /* webpackChunkName: "component---src-pages-green-coffee-magic-tsx" */),
  "component---src-pages-high-school-lesson-1-introduction-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-introduction-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-introduction-tsx": () => import("./../../../src/pages/high-school/lesson-1/introduction.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-introduction-tsx" */),
  "component---src-pages-high-school-lesson-1-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-0-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-0.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-0-tsx" */),
  "component---src-pages-high-school-lesson-1-step-1-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-1-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-1-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-1.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-1-tsx" */),
  "component---src-pages-high-school-lesson-1-step-10-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-10/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-10-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-10-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-10.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-10-tsx" */),
  "component---src-pages-high-school-lesson-1-step-2-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-2-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-2-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-2.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-2-tsx" */),
  "component---src-pages-high-school-lesson-1-step-3-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-3-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-3-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-3.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-3-tsx" */),
  "component---src-pages-high-school-lesson-1-step-4-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-4-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-4-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-4.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-4-tsx" */),
  "component---src-pages-high-school-lesson-1-step-5-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-5-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-5-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-5.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-5-tsx" */),
  "component---src-pages-high-school-lesson-1-step-6-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-6-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-6-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-6.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-6-tsx" */),
  "component---src-pages-high-school-lesson-1-step-7-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-7-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-7-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-7.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-7-tsx" */),
  "component---src-pages-high-school-lesson-1-step-8-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-8-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-8-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-8.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-8-tsx" */),
  "component---src-pages-high-school-lesson-1-step-9-notes-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-9/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-9-notes-tsx" */),
  "component---src-pages-high-school-lesson-1-step-9-tsx": () => import("./../../../src/pages/high-school/lesson-1/step-9.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-1-step-9-tsx" */),
  "component---src-pages-high-school-lesson-2-introduction-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-introduction-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-introduction-tsx": () => import("./../../../src/pages/high-school/lesson-2/introduction.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-introduction-tsx" */),
  "component---src-pages-high-school-lesson-2-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-0-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-0/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-0-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-1-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-1-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-1-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-1.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-1-tsx" */),
  "component---src-pages-high-school-lesson-2-step-10-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-10/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-10-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-10-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-10.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-10-tsx" */),
  "component---src-pages-high-school-lesson-2-step-2-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-2-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-2-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-2.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-2-tsx" */),
  "component---src-pages-high-school-lesson-2-step-3-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-3-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-3-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-3.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-3-tsx" */),
  "component---src-pages-high-school-lesson-2-step-4-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-4-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-4-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-4.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-4-tsx" */),
  "component---src-pages-high-school-lesson-2-step-5-a-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-5a.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-5-a-tsx" */),
  "component---src-pages-high-school-lesson-2-step-5-b-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-5b.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-5-b-tsx" */),
  "component---src-pages-high-school-lesson-2-step-5-c-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-5c.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-5-c-tsx" */),
  "component---src-pages-high-school-lesson-2-step-5-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-5-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-5-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-5.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-5-tsx" */),
  "component---src-pages-high-school-lesson-2-step-6-a-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-6a.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-6-a-tsx" */),
  "component---src-pages-high-school-lesson-2-step-6-b-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-6b.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-6-b-tsx" */),
  "component---src-pages-high-school-lesson-2-step-6-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-6-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-6-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-6.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-6-tsx" */),
  "component---src-pages-high-school-lesson-2-step-7-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-7-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-7-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-7.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-7-tsx" */),
  "component---src-pages-high-school-lesson-2-step-8-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-8-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-8-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-8.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-8-tsx" */),
  "component---src-pages-high-school-lesson-2-step-9-notes-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-9/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-9-notes-tsx" */),
  "component---src-pages-high-school-lesson-2-step-9-tsx": () => import("./../../../src/pages/high-school/lesson-2/step-9.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-2-step-9-tsx" */),
  "component---src-pages-high-school-lesson-3-introduction-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-introduction-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-introduction-tsx": () => import("./../../../src/pages/high-school/lesson-3/introduction.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-introduction-tsx" */),
  "component---src-pages-high-school-lesson-3-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-0-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-0/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-0-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-1-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-1-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-1-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-1.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-1-tsx" */),
  "component---src-pages-high-school-lesson-3-step-2-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-2-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-2-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-2.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-2-tsx" */),
  "component---src-pages-high-school-lesson-3-step-3-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-3-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-3-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-3.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-3-tsx" */),
  "component---src-pages-high-school-lesson-3-step-4-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-4-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-4-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-4.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-4-tsx" */),
  "component---src-pages-high-school-lesson-3-step-5-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-5-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-5-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-5.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-5-tsx" */),
  "component---src-pages-high-school-lesson-3-step-6-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-6-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-6-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-6.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-6-tsx" */),
  "component---src-pages-high-school-lesson-3-step-7-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-7-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-7-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-7.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-7-tsx" */),
  "component---src-pages-high-school-lesson-3-step-8-notes-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-8-notes-tsx" */),
  "component---src-pages-high-school-lesson-3-step-8-tsx": () => import("./../../../src/pages/high-school/lesson-3/step-8.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-3-step-8-tsx" */),
  "component---src-pages-high-school-lesson-4-introduction-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-introduction-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-introduction-tsx": () => import("./../../../src/pages/high-school/lesson-4/introduction.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-introduction-tsx" */),
  "component---src-pages-high-school-lesson-4-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-1-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-1-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-1-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-1.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-1-tsx" */),
  "component---src-pages-high-school-lesson-4-step-10-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-10/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-10-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-10-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-10.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-10-tsx" */),
  "component---src-pages-high-school-lesson-4-step-11-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-11/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-11-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-11-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-11.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-11-tsx" */),
  "component---src-pages-high-school-lesson-4-step-12-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-12/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-12-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-12-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-12.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-12-tsx" */),
  "component---src-pages-high-school-lesson-4-step-13-a-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-13a.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-13-a-tsx" */),
  "component---src-pages-high-school-lesson-4-step-13-b-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-13b.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-13-b-tsx" */),
  "component---src-pages-high-school-lesson-4-step-13-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-13/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-13-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-13-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-13.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-13-tsx" */),
  "component---src-pages-high-school-lesson-4-step-14-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-14/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-14-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-14-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-14.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-14-tsx" */),
  "component---src-pages-high-school-lesson-4-step-15-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-15/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-15-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-15-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-15.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-15-tsx" */),
  "component---src-pages-high-school-lesson-4-step-2-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-2-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-2-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-2.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-2-tsx" */),
  "component---src-pages-high-school-lesson-4-step-3-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-3-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-3-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-3.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-3-tsx" */),
  "component---src-pages-high-school-lesson-4-step-4-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-4-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-4-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-4.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-4-tsx" */),
  "component---src-pages-high-school-lesson-4-step-5-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-5-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-5-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-5.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-5-tsx" */),
  "component---src-pages-high-school-lesson-4-step-6-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-6-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-6-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-6.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-6-tsx" */),
  "component---src-pages-high-school-lesson-4-step-7-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-7-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-7-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-7.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-7-tsx" */),
  "component---src-pages-high-school-lesson-4-step-8-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-8-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-8-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-8.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-8-tsx" */),
  "component---src-pages-high-school-lesson-4-step-9-notes-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-9/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-9-notes-tsx" */),
  "component---src-pages-high-school-lesson-4-step-9-tsx": () => import("./../../../src/pages/high-school/lesson-4/step-9.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-4-step-9-tsx" */),
  "component---src-pages-high-school-lesson-5-introduction-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-introduction-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-introduction-tsx": () => import("./../../../src/pages/high-school/lesson-5/introduction.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-introduction-tsx" */),
  "component---src-pages-high-school-lesson-5-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-1-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-1-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-1-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-1.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-1-tsx" */),
  "component---src-pages-high-school-lesson-5-step-2-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-2-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-2-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-2.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-2-tsx" */),
  "component---src-pages-high-school-lesson-5-step-3-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-3-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-3-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-3.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-3-tsx" */),
  "component---src-pages-high-school-lesson-5-step-4-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-4-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-4-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-4.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-4-tsx" */),
  "component---src-pages-high-school-lesson-5-step-5-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-5-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-5-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-5.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-5-tsx" */),
  "component---src-pages-high-school-lesson-5-step-6-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-6-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-6-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-6.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-6-tsx" */),
  "component---src-pages-high-school-lesson-5-step-7-notes-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-7-notes-tsx" */),
  "component---src-pages-high-school-lesson-5-step-7-tsx": () => import("./../../../src/pages/high-school/lesson-5/step-7.tsx" /* webpackChunkName: "component---src-pages-high-school-lesson-5-step-7-tsx" */),
  "component---src-pages-high-school-supplemental-materials-documents-notes-tsx": () => import("./../../../src/pages/high-school/supplemental-materials/documents/notes.tsx" /* webpackChunkName: "component---src-pages-high-school-supplemental-materials-documents-notes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-class-tsx": () => import("./../../../src/pages/join-class.tsx" /* webpackChunkName: "component---src-pages-join-class-tsx" */),
  "component---src-pages-middle-school-lesson-1-introduction-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-introduction-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-introduction-tsx": () => import("./../../../src/pages/middle-school/lesson-1/introduction.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-introduction-tsx" */),
  "component---src-pages-middle-school-lesson-1-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-1-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-1-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-1-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-1.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-1-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-10-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-10/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-10-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-10-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-10.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-10-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-2-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-2-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-2-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-2.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-2-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-3-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-3-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-3-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-3.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-3-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-4-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-4-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-4-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-4.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-4-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-5-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-5-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-5-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-5.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-5-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-6-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-6-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-6-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-6.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-6-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-7-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-7-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-7-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-7.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-7-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-8-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-8-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-8-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-8.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-8-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-9-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-9/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-9-notes-tsx" */),
  "component---src-pages-middle-school-lesson-1-step-9-tsx": () => import("./../../../src/pages/middle-school/lesson-1/step-9.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-1-step-9-tsx" */),
  "component---src-pages-middle-school-lesson-2-introduction-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-introduction-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-introduction-tsx": () => import("./../../../src/pages/middle-school/lesson-2/introduction.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-introduction-tsx" */),
  "component---src-pages-middle-school-lesson-2-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-0-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-0/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-0-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-1-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-1-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-1-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-1.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-1-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-10-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-10/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-10-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-10-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-10.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-10-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-2-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-2-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-2-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-2.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-2-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-3-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-3-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-3-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-3.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-3-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-4-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-4-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-4-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-4.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-4-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-5-a-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-5a.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-5-a-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-5-b-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-5b.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-5-b-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-5-c-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-5c.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-5-c-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-5-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-5-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-5-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-5.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-5-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-6-a-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-6a.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-6-a-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-6-b-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-6b.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-6-b-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-6-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-6-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-6-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-6.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-6-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-7-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-7-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-7-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-7.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-7-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-8-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-8-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-8-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-8.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-8-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-9-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-9/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-9-notes-tsx" */),
  "component---src-pages-middle-school-lesson-2-step-9-tsx": () => import("./../../../src/pages/middle-school/lesson-2/step-9.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-2-step-9-tsx" */),
  "component---src-pages-middle-school-lesson-3-introduction-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-introduction-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-introduction-tsx": () => import("./../../../src/pages/middle-school/lesson-3/introduction.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-introduction-tsx" */),
  "component---src-pages-middle-school-lesson-3-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-0-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-0/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-0-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-1-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-1-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-1-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-1.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-1-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-2-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-2-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-2-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-2.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-2-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-3-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-3-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-3-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-3.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-3-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-4-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-4-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-4-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-4.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-4-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-5-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-5-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-5-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-5.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-5-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-6-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-6-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-6-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-6.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-6-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-7-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-7-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-7-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-7.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-7-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-8-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-8-notes-tsx" */),
  "component---src-pages-middle-school-lesson-3-step-8-tsx": () => import("./../../../src/pages/middle-school/lesson-3/step-8.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-3-step-8-tsx" */),
  "component---src-pages-middle-school-lesson-4-introduction-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-introduction-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-introduction-tsx": () => import("./../../../src/pages/middle-school/lesson-4/introduction.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-introduction-tsx" */),
  "component---src-pages-middle-school-lesson-4-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-1-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-1-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-1-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-1.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-1-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-10-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-10/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-10-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-10-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-10.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-10-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-11-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-11/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-11-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-11-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-11.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-11-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-12-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-12/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-12-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-12-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-12.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-12-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-13-a-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-13a.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-13-a-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-13-b-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-13b.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-13-b-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-13-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-13/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-13-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-13-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-13.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-13-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-14-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-14/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-14-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-14-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-14.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-14-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-15-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-15/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-15-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-15-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-15.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-15-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-2-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-2-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-2-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-2.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-2-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-3-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-3-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-3-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-3.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-3-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-4-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-4-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-4-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-4.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-4-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-5-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-5-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-5-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-5.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-5-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-6-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-6-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-6-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-6.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-6-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-7-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-7-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-7-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-7.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-7-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-8-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-8/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-8-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-8-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-8.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-8-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-9-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-9/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-9-notes-tsx" */),
  "component---src-pages-middle-school-lesson-4-step-9-tsx": () => import("./../../../src/pages/middle-school/lesson-4/step-9.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-4-step-9-tsx" */),
  "component---src-pages-middle-school-lesson-5-introduction-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/introduction/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-introduction-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-introduction-tsx": () => import("./../../../src/pages/middle-school/lesson-5/introduction.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-introduction-tsx" */),
  "component---src-pages-middle-school-lesson-5-lesson-at-a-glance-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/lesson-at-a-glance/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-lesson-at-a-glance-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-1-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-1/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-1-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-1-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-1.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-1-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-2-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-2/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-2-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-2-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-2.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-2-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-3-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-3/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-3-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-3-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-3.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-3-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-4-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-4/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-4-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-4-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-4.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-4-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-5-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-5/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-5-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-5-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-5.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-5-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-6-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-6/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-6-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-6-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-6.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-6-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-7-notes-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-7/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-7-notes-tsx" */),
  "component---src-pages-middle-school-lesson-5-step-7-tsx": () => import("./../../../src/pages/middle-school/lesson-5/step-7.tsx" /* webpackChunkName: "component---src-pages-middle-school-lesson-5-step-7-tsx" */),
  "component---src-pages-middle-school-supplemental-materials-documents-notes-tsx": () => import("./../../../src/pages/middle-school/supplemental-materials/documents/notes.tsx" /* webpackChunkName: "component---src-pages-middle-school-supplemental-materials-documents-notes-tsx" */),
  "component---src-pages-new-password-tsx": () => import("./../../../src/pages/new-password.tsx" /* webpackChunkName: "component---src-pages-new-password-tsx" */),
  "component---src-pages-nih-health-information-nih-clinical-research-trials-you-basics-highlighted-content-tsx": () => import("./../../../src/pages/nih/health-information/nih-clinical-research-trials-you/basics/highlighted-content.tsx" /* webpackChunkName: "component---src-pages-nih-health-information-nih-clinical-research-trials-you-basics-highlighted-content-tsx" */),
  "component---src-pages-nih-health-information-nih-clinical-research-trials-you-basics-index-tsx": () => import("./../../../src/pages/nih/health-information/nih-clinical-research-trials-you/basics/index.tsx" /* webpackChunkName: "component---src-pages-nih-health-information-nih-clinical-research-trials-you-basics-index-tsx" */),
  "component---src-pages-prescription-drug-ad-tsx": () => import("./../../../src/pages/prescription-drug-ad.tsx" /* webpackChunkName: "component---src-pages-prescription-drug-ad-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */)
}

