import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import axios from "axios";
import { get, post, put, remove } from '~/helpers/api';
import { isEmpty } from '~/utils';


interface State {
  classCode: string;
  deleteClass: any;
  getUser: any;
  loading: boolean;
  loginUser: any;
  logout: any;
  register: any;
  updateUser: any;
  setClassCode: Dispatch<SetStateAction<string>> | (() => void);
  user: any;
};

const INITIAL_STATE: State = {
  deleteClass: (id: string) => { return axios.post("") },
  classCode: "",
  setClassCode: () => {},
  getUser: async () => {},
  loading: true,
  loginUser: (username: string, password: string) => { return axios.post("") },
  logout: () => {}, //async () => { return }
  register: (data: any) => { return axios.post("") },
  updateUser: async (data: any) => { return axios.post("") },
  user: null
}

export const MainContext = React.createContext(INITIAL_STATE);

export const MainProvider: React.FC = ({ children }) => {
  const [classCode, setClassCode] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoadingStatus] = useState(true)

  useEffect(() => {
    if (!isEmpty(user)) return;
    getUser();
  }, [])

  useEffect(() => {
    const savedClassCode: string | null = localStorage.getItem("class_code");
    setClassCode(savedClassCode ?? "");
  }, []);

  const getUser = async () => {
    return get('/getUser')
      .then((data => {
        data?.user?.me && setUser(data.user.me)
        setLoadingStatus(false)
      }))
      .catch(error => {
        console.error(error.message)
        setLoadingStatus(false)
      })
  }

  const logout = async () => {
    await post('/logout', undefined);
    localStorage.removeItem('user');
    setUser(null);
  }

  const updateUser = async (data: any) => {
    return put('/updateUser', data)
  }

  const deleteClass = (id: string) => {
    return remove('/deleteClass', { data: { data: id }});
  }

  const loginUser = (username: string, password: string) => {
    return post('/login', {
      username,
      password,
    })
  }

  const register = (data: any) => {
    return post('/register', data);
  }

  if (loading) return null;

  return (
    <MainContext.Provider value={{
      classCode,
      setClassCode,
      getUser,
      loginUser,
      updateUser,
      loading,
      logout,
      user,
      deleteClass,
      register
    }}>
      {children}
    </MainContext.Provider>
  )
}
