export const formatClassList = (classList:string): string => {
  return classList
    .replace(/\n/g, '')
    .replace(/[\s]+/g, ' ')
    .trim()
}

export const joinStrings = (join: string, ...strings: string[]): string => {
  return strings.join(join)
}

export const appendVariantClasses = (classList: string, variants: Record<string, string>, variant:string): string => {
  if (variants.hasOwnProperty(variant)) {
    return joinStrings(' ', classList, variants[variant])
  }

  return classList
}

export const removeUniqueValueFromArray = <T>(arr: T[], element: T): T[] => {
  let newArray: T[] = []

  for (let i: number = 0; i < arr.length; i++) {
    if (arr[i] !== element) {
      newArray.push(arr[i])
    }
  }

  return newArray
}

export const trimSlashes = (path: string): string => {
  if (path[0] === '/') {
    path = path.slice(1)
  }

  if (path[path.length - 1] === '/') {
    path = path.slice(0, -1)
  }

  return path
}

export const isPageActive = (path: string): boolean => {
  if (typeof window !== 'undefined') {
    const currentPath = document.location.pathname
    const currentHash = document.location.hash

    const formattedPath: string = trimSlashes(path)
    const formattedCurrentPath: string = trimSlashes(currentPath)
    // need to check if first & last char is '/'
    if (
      formattedCurrentPath === formattedPath
      || trimSlashes(currentPath + currentHash) === formattedPath
    ) {
      return true
    }

    return false
  }

  return false
}

export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

export const isMs = (): boolean => {
  const globalWindow: Window | undefined = typeof window !== "undefined" ? window : undefined;

  if (!globalWindow) return false;

  return globalWindow?.location.pathname.split("/")[1] === "middle-school";
}

