import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/.netlify/functions',
  timeout: 5000,
});

axiosInstance.interceptors.request.use(function (config) {
  const AUTH_TOKEN = localStorage.getItem("user");
  config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {  
  if(response.data && response?.data?.token) {
    localStorage.setItem('user', response.data.token)
  }

  return response.data;
}, function (error) {
  
  return Promise.reject(error);
});

export const get = (url: string) => axiosInstance.get(url)
export const post = (url: string, data: any) => axiosInstance.post(url, data)
export const put = (url: string, data: any) => axiosInstance.put(url, data)
export const remove = (url: string, data: any) => axiosInstance.delete(url, data)
